@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Metal+Mania&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'IBM Plex Sans', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: -apple-system, BlinkMacSystemFont, 'Metal Mania', serif;
}

.hero {
	background-color: black;
	color: hsl(0, 0%, 95%);
	height: 70vh;
	text-align: center;
	background-image: url('/Bg-header.jpg');
}

.brand img {
	margin-top: 3em;
	height: 400px;
	width: auto;
}
.primaryNavigation {
	margin: 0;
	padding: 0;
	display: flex;
	list-style: none;
	width: 50%;
	margin: 0 auto;
	justify-content: space-between;
	font-family: "Metal Mania", serif;
	font-size: 170%;
}

.primaryNavigation__link {
	color: inherit;
	text-decoration: none;
}
.counter {
	background-color: #940303;
	display: flex;
	width: 300px;
	justify-content: space-between;
	padding: .5em 1em;
	position: relative;
	left: 50%;
	bottom: -70px;
	margin-left: -150px;
}

.counter__time {
	font-family: "Metal Mania";
	display: block;
	font-size: 200%;
}

main {
	width: 80vw;
	margin: 0 auto;
}
